<template>
  <b-container>
    <div class="px-2 pt-2 pb-0">
      <feather-icon
        @click="() => $router.push({ name: 'auth-login' })"
        icon="HomeIcon"
        class="mb-1 home__icon cursor-pointer"
        size="40"
      />
    </div>
    <b-row>
      <b-col class="d-md-block d-none">
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <b-col class="px-4 py-2">
        <b-overlay :show="loading" rounded="sm" variant="transparent">
          <template #overlay>
            <div class="text-center">
              <b-spinner />
              <p class="mt-1">Registrando usuario</p>
            </div>
          </template>
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="horizontal"
            :finish-button-text="
              loading
                ? 'Procesando...'
                : $route.meta.isEstablishmentCustomer !== true
                ? 'Confirmar'
                : $route.meta.isEstablishmentCustomer === true &&
                  addWalleatToCustomer === false
                ? 'Confirmar'
                : 'Siguiente'
            "
            next-button-text="Siguiente"
            back-button-text="Regresar"
            class="wizard-vertical mb-3"
            shape="square"
            step-size="sm"
            @on-complete="formSubmitted"
          >
            <!-- Nombre -->
            <tab-content title="Nombre" :before-change="validationForm">
              <validation-observer ref="accountRules" tag="form">
                <b-row>
                  <b-col cols="12" class="mt-1 mb-50">
                    <h5 class="mb-0">Nombre del usuario</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-ammount">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-input-group class="">
                          <b-form-input
                            id="v-ammount"
                            v-model="user.name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="José López"
                            size="lg"
                            type="text"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="$route.meta.isEstablishmentCustomer === true">
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">
                      ¿Cuánto es lo máximo que le vas a fiar a este cliente?
                    </h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loanBalanceLimit">
                      <validation-provider
                        #default="{ errors }"
                        name="Loan Balance Limit"
                        rules="required"
                      >
                        <b-input-group class="">
                          <b-form-input
                            id="v-loanBalanceLimit"
                            v-model="
                              user.loan_limit_attributes.balance_loan_limit
                            "
                            :state="errors.length > 0 ? false : null"
                            placeholder="100"
                            size="lg"
                            type="number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-1 mb-50">
                    <h5 class="mb-0">Teléfono celular</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldPhone">
                      <validation-provider
                        #default="{ errors }"
                        name="El celular es necesario para validar su cuenta"
                        rules="required|numeric|digits:10"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldPhone"
                            v-model="user.phone_number"
                            :state="errors.length > 0 ? false : null"
                            size="lg"
                            maxlength="10"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" class="mt-1 mb-50">
                    <h5 class="mb-0">Correo electrónico</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldEmail">
                      <validation-provider
                        #default="{ errors }"
                        name="El correo electrónico es necesario"
                        rules="required|email"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldEmail"
                            v-model="user.email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="email"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Dirección -->
            <!-- 
            <tab-content
              title="Dirección"
              :before-change="validationDirForm"
            >
              <validation-observer
                ref="accountDirRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="my-2"
                  >
                    <small class="text-warning">
                      La siguiente dirección será utilizada para pedidos realizados en línea. Recuerda que
                      posteriormente puede ser modificada. Si no encuentras tu calle, escribe el nombre de
                      tu ciudad y cuando el mapa este en tu ciudad, presiona en tu ubicación
                    </small>
                  </b-col>
                  <b-col cols="12">
                    <!- TODO: Add the element to search the directionon the google maps -->
            <!-- <address-form-model info-type @getAddress="setAddressForEdit($event)" /> -->
            <!-- 
                    <gmap-autocomplete
                        ref="googleAddress"
                        class="mb-2"
                        :componentRestrictions="{country: 'mx'}"
                        @place_changed="setAddressForEdit($event)"
                      />
                    <GmapMap @click="setMarker($event)" :center="center" :zoom="zoom" map-type-id="roadmap"
                      style="width: 500px; height: 300px">
                      <GmapMarker :position="marker" :clickable="true"
                        :draggable="false" @update="changeMarker($event)"/>
                    </GmapMap>

                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
            -->

            <!-- Add Walleat -->
            <tab-content
              v-if="$route.meta.isEstablishmentCustomer"
              title="Agregar tarjeta Walleat"
            >
              <b-row>
                <b-col>
                  <div class="mb-1 d-flex mt-2">
                    <b-form-group
                      label="¿Deseas vincular una tarjeta Walleat al cliente?"
                      label-for="is-weighted"
                      label-size="lg"
                    >
                      <b-button-group
                        id="add-walleat-card"
                        v-model="addWalleatToCustomer"
                        class="d-flex align-items-center"
                      >
                        <b-button
                          :variant="
                            addWalleatToCustomer ? 'success' : 'outline-success'
                          "
                          @click="handleCustomerWalleatButton(true)"
                          >Sí</b-button
                        >
                        <b-button
                          :variant="
                            addWalleatToCustomer === false
                              ? 'danger'
                              : 'outline-danger'
                          "
                          @click="handleCustomerWalleatButton(false)"
                          >No</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </tab-content>

            <!-- Resumen de información -->
            <tab-content title="Confirmar">
              <b-row>
                <b-col>
                  <div class="mb-1">
                    <p class="mb-0 text-muted">Nombre:</p>
                    <p class="display-5">
                      {{ user.name }}
                    </p>
                    <p class="mb-0 text-muted">Correo electrónico:</p>
                    <p class="display-5">
                      {{ user.email }}
                    </p>
                    <p class="mb-0 text-muted">Celular:</p>
                    <p class="display-5">
                      {{ user.phone_number }}
                    </p>
                    <p
                      v-if="$route.meta.isEstablishmentCustomer === true"
                      class="mb-0 text-muted"
                    >
                      Límite de crédito:
                    </p>
                    <p
                      v-if="$route.meta.isEstablishmentCustomer === true"
                      class="display-5"
                    >
                      {{ user.loan_limit_attributes.balance_loan_limit }}
                    </p>
                  </div>
                  <!-- 
                  <b-row>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          Calle y número :
                        </span>
                        {{ user.customer_attributes.address_attributes.street }}, #{{ user.customer_attributes.address_attributes.ext_number }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          Código Postal:
                        </span>
                        {{ user.customer_attributes.address_attributes.postal_code }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="d-flex flex-column">
                        <span class="text-muted">
                          País y cuidad:
                        </span>
                        {{ user.customer_attributes.address_attributes.country }}, {{ user.customer_attributes.address_attributes.city }}
                      </p>
                    </b-col>
                  </b-row>
                  -->
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import { FormWizard, TabContent } from "vue-form-wizard"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email } from "@validations"
import "@/@core/scss/vue/libs/vue-wizard.scss"
import AddressFormModel from "@/@core/components/CustomerAddressFormModel.vue"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { getGoogleMapsAPI } from "gmap-vue"
import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddressFormModel,
  },
  data() {
    return {
      addWalleatToCustomer: null,
      user: {
        name: null,
        email: null,
        phone_number: null,
        customer_attributes: {
          cel_number: null,
          address_attributes: {},
        },
        bracelet_attributes: {
          name: null,
          daily_limit: null,
          bracelet_number: null,
          active_status: null,
          logo: null,
        },
        loan_limit_attributes: {
          balance_loan_limit: null,
        },
      },
      emptyAddress: {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: "",
        lng: "",
        suburb: "",
        url: "",
        formatted_address: "",
      },
      required,
      email,
      loading: false,
      marker: {},
      center: { lat: 25.6866142, lng: -100.3161126 },
      zoom: 10,
      sideImg: require("@/assets/images/pages/login-v2-dark.svg"),
      token: this.$route.query.token,
    }
  },
  methods: {
    ...mapMutations("users", ["saveCustomerRegistrationData"]),
    ...mapActions("users", ["registerCustomer"]),
    handleCustomerWalleatButton(value) {
      this.addWalleatToCustomer = value
    },
    getAddress(place) {
      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: "",
        lng: "",
        suburb: "",
        url: "",
        formatted_address: "",
      }
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name
        }
        if (x.types.includes("country")) {
          address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    setMarker(event) {
      // goecode manual marker to get address address
      // eslint-disable-next-line
      const mapsapi = new getGoogleMapsAPI()
      // console.log(mapsapi.maps.Geocoder.prototype.geocode);
      // const geocoder = new google.gmapApi().maps.Geocoder
      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address
              this.user.customer_attributes.address_attributes =
                this.getAddress(results[1])
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Introduzca la direccion manualmente",
                  icon: "EditIcon",
                  variant: "danger",
                },
              })
            }
          }
        }
      )
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      this.user.customer_attributes.address_attributes.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      this.marker = {
        lat,
        lng,
      }
    },
    setAddressForEdit(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.user.customer_attributes.address_attributes = this.getAddress(place)
    },
    formSubmitted() {
      this.loading = true
      this.user.customer_attributes.address_attributes = this.emptyAddress // Temporal fix to send empty address and avoid having issues with online sales
      this.user.customer_attributes.cel_number = `${52}${
        this.user.phone_number
      }`

      if (this.token) {
        const decodedToken = decodeURIComponent(this.token)
        this.user.encrypted_message = decodedToken
      }

      const customer = {
        ...this.user,
        phone_number: `${52}${this.user.phone_number}`,
      }
      if (
        this.$route.meta.isEstablishmentCustomer !== true ||
        (this.$route.meta.isEstablishmentCustomer === true &&
          this.addWalleatToCustomer === false)
      ) {
        this.registerCustomer(customer)
          .then(() => {
            this.$swal({
              title: "Usuario registrado exitosamente!",
              text: "Revisa tus mensajes de texto para terminar tu registro.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: "home" })
          })
          .catch((error) => {
            if (error.response.data.messages) {
              this.$swal({
                title: "Error!",
                text: error.response.data.messages[0],
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              })
            }
          })
          .then(() => {
            this.loading = false
          })
      } else {
        this.saveCustomerRegistrationData(customer)
        this.$router.push({ name: "new-walleat-for-establishment-customer" })
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationDirForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountDirRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>
<style lang="scss">
.wizard-icon-container {
  border-radius: 6px !important;
  background: red;
}
.wizard-nav.wizard-nav-pills {
  display: none;
}
</style>

<style lang="scss" scoped>
.home__icon {
  z-index: 999;
  color: #7367f0;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 2rem;
    left: 2rem;
  }
}
</style>
